import React, { useMemo } from 'react';
import { Box, Button, Divider, Drawer, Typography } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import BackupIcon from '@mui/icons-material/Backup';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HomeIcon from '@mui/icons-material/Home';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NavItem from './NavItem';
import SendIcon from '@mui/icons-material/Send';
import WorkIcon from '@mui/icons-material/Work';

const SideNavbar: React.FC = () => {
  const { auth, logout } = useAuth();
  const navigation = useNavigate();

  const items = useMemo(() => {
    return [
      {
        href: '/',
        icon: <HomeIcon fontSize="small" />,
        title: 'Inicio',
      },
      {
        href: '/sugestao-de-preco',
        icon: <AssignmentTurnedInIcon fontSize="small" />,
        title: 'Sugestão de preço',
      },
      {
        href: '/upload-preco-mensal',
        icon: <BackupIcon fontSize="small" />,
        title: 'Upload CSV',
      },
      {
        href: `${process.env.REACT_APP_API_URL}/admin/queues?accessToken=${auth?.accessToken}`,
        icon: <WorkIcon fontSize="small" />,
        title: 'Dashboard',
      },
      {
        href: `/postos`,
        icon: <LocationOnIcon />,
        title: 'Postos',
      },
      {
        href: `/notificacoes`,
        icon: <SendIcon />,
        title: 'Enviar notificações',
      },
    ];
  }, [auth?.accessToken]);

  const content = (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <div>
          <Box sx={{ px: 2, pt: 2 }}>
            <Box
              sx={{
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.04)',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                px: 3,
                py: '11px',
                borderRadius: 1,
              }}
            >
              <div>
                <Typography color="inherit" variant="subtitle1">
                  Olá, {auth?.user.name}
                </Typography>
              </div>
            </Box>
          </Box>
        </div>

        <Divider
          sx={{
            borderColor: '#2D3748',
            my: 3,
          }}
        />

        <Box sx={{ flexGrow: 1 }}>
          {items.map((item) => (
            <NavItem key={item.title} icon={item.icon} href={item.href} title={item.title} />
          ))}
        </Box>

        <Divider sx={{ borderColor: '#2D3748' }} />

        <Box
          sx={{
            display: 'flex',
            my: 3,
            py: 0,
            px: 2,
          }}
        >
          <Button
            startIcon={<ExitToAppIcon />}
            disableRipple
            onClick={() => {
              logout();
              navigation('/');
            }}
            sx={
              {
                borderRadius: 1,
                color: 'neutral.300',
                fontWeight: 'fontWeightBold',
                justifyContent: 'flex-start',
                px: 3,
                textAlign: 'left',
                textTransform: 'none',
                width: '100%',
                '& .MuiButton-startIcon': {
                  color: 'neutral.400',
                },
                '&:hover': {
                  backgroundColor: 'rgba(255,255,255, 0.08)',
                },
              } as any
            }
          >
            <Box sx={{ flexGrow: 1 }}>Sair</Box>
          </Button>
        </Box>
      </Box>
    </>
  );

  return (
    <Drawer
      anchor="left"
      open
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 280,
        },
      }}
      variant="persistent"
    >
      {content}
    </Drawer>
  );
};

export default SideNavbar;
