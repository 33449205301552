import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  List,
  ListItem,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import CurrencyInput from '../../components/CurrencyInput';
import { FuelType, getEnumValues, getFuelTypeLabel } from '../../services/enums';
import useGetFuelSuggestion from '../../services/useGetFuelSuggestion';
import useValidateFuelSuggestion from '../../services/useValidateFuelSuggestion';
import { parseCurrencyToFloat } from '../../utils/currency';
import { formatBrazilianDate, parseBrazilianDate } from '../../utils/date';
import { formatDistance } from '../../utils/distance';

const FuelSuggestion: React.FC = () => {
  const { fuelSuggestion, isLoading, refetch } = useGetFuelSuggestion();
  const { fuelSuggestionSuccess, fuelSuggestionError, mutateAsync, reset } = useValidateFuelSuggestion();
  const [referenceDate, setReferenceDate] = useState<Date | undefined>(undefined);
  const [prices, setPrices] = useState<any>({});

  const formatedData = Object.keys(prices)
    .filter((key) => prices[key])
    .map((key) => ({
      fuelType: FuelType[key as keyof typeof FuelType],
      price: prices[key],
    }));

  const resetForm = () => {
    setReferenceDate(undefined);
    setPrices({});
  };

  const handleApprove = async () => {
    if (fuelSuggestion) {
      resetForm();
      await mutateAsync({
        approved: true,
        data: formatedData,
        fuelSuggestionId: fuelSuggestion?.id,
        referenceDate: referenceDate,
      });
      await refetch();
    }
  };

  const handleReprove = async () => {
    if (fuelSuggestion) {
      resetForm();
      await mutateAsync({ approved: false, data: [], fuelSuggestionId: fuelSuggestion?.id! });
      await refetch();
    }
  };

  const handleChange = (event: any) => {
    setPrices((prev: any) => ({
      ...prev,
      [event.target.name]: parseCurrencyToFloat(event.target.value),
    }));
  };

  return (
    <Container maxWidth={false}>
      <Box>
        <Typography variant="h4">Sugestão de preço</Typography>
      </Box>

      {fuelSuggestion && (
        <>
          <Typography variant="body1">{fuelSuggestion.totalSuggestions} Sugestões restantes</Typography>

          {isLoading && <CircularProgress />}

          <Grid container mt={2}>
            <Grid item md={12} lg={6}>
              {fuelSuggestion.totalSuggestions > 0 && (
                <a href={fuelSuggestion.imageUrl} target="_blank" rel="noreferrer">
                  <img
                    width="100%"
                    height="800px"
                    style={{ objectFit: 'contain', cursor: 'pointer' }}
                    src={`${fuelSuggestion.imageUrl}`}
                    alt="Sugestão de preço"
                  />
                </a>
              )}
            </Grid>

            <Grid item md={12} lg={6} paddingX={2}>
              <Box>
                <List dense>
                  <ListItem>
                    <Typography fontWeight="bold">Criado por:</Typography>
                    <Typography>{fuelSuggestion.createBy}</Typography>
                  </ListItem>

                  <ListItem>
                    <Typography fontWeight="bold">Criado em:</Typography>
                    <Typography>{formatBrazilianDate(fuelSuggestion.createdAt)}</Typography>
                  </ListItem>

                  <ListItem>
                    <Typography fontWeight="bold">Data de referência:</Typography>
                    <Typography>{formatBrazilianDate(fuelSuggestion.referenceDate)}</Typography>
                  </ListItem>

                  <ListItem>
                    <Typography fontWeight="bold">Distância do posto:</Typography>
                    <Typography>{formatDistance(fuelSuggestion.distanceFromGasStation)}</Typography>
                  </ListItem>

                  <ListItem>
                    <Typography fontWeight="bold">Nome:</Typography>
                    <Typography>{fuelSuggestion.gasStation.name}</Typography>
                  </ListItem>

                  <ListItem>
                    <Typography fontWeight="bold">Razão Social:</Typography>
                    <Typography>{fuelSuggestion.gasStation.corporateName}</Typography>
                  </ListItem>

                  <ListItem>
                    <Typography fontWeight="bold">CNPJ:</Typography>
                    <Typography>{fuelSuggestion.gasStation.CNPJ}</Typography>
                  </ListItem>

                  <ListItem>
                    <Typography fontWeight="bold">Bandeira:</Typography>
                    <Typography>{fuelSuggestion.gasStation.flag}</Typography>
                  </ListItem>

                  <ListItem>
                    <Typography fontWeight="bold">Estado:</Typography>
                    <Typography>{fuelSuggestion.gasStation.state}</Typography>
                  </ListItem>

                  <ListItem>
                    <Typography fontWeight="bold">Cidade:</Typography>
                    <Typography>{fuelSuggestion.gasStation.city}</Typography>
                  </ListItem>

                  <ListItem>
                    <Typography fontWeight="bold">Bairro:</Typography>
                    <Typography>{fuelSuggestion.gasStation.district}</Typography>
                  </ListItem>

                  <ListItem>
                    <Typography fontWeight="bold">Endereço:</Typography>
                    <Typography>{fuelSuggestion.gasStation.address}</Typography>
                  </ListItem>

                  <ListItem>
                    <Typography fontWeight="bold">Número:</Typography>
                    <Typography>{fuelSuggestion.gasStation.residentialNumber}</Typography>
                  </ListItem>

                  <ListItem>
                    <Typography fontWeight="bold">Complemento:</Typography>
                    <Typography>{fuelSuggestion.gasStation.complement}</Typography>
                  </ListItem>

                  <ListItem>
                    <Typography fontWeight="bold">CEP:</Typography>
                    <Typography>{fuelSuggestion.gasStation.zipCode}</Typography>
                  </ListItem>
                </List>
              </Box>

              <form style={{ width: '100%' }}>
                <Paper
                  style={{
                    width: '100%',
                    display: 'grid',
                    gridRowGap: '20px',
                    padding: '20px',
                  }}
                >
                  <TextField
                    id="referenceDate"
                    name="referenceDate"
                    label="Data de referência"
                    onChange={(evt) => setReferenceDate(parseBrazilianDate(evt.target.value))}
                    variant="outlined"
                  />

                  {getEnumValues(FuelType).map((value) => (
                    <TextField
                      key={value.toString()}
                      id={value.toString()}
                      name={value.toString()}
                      label={getFuelTypeLabel(value as FuelType)}
                      InputProps={{ inputComponent: CurrencyInput as any }}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  ))}

                  <Button
                    disabled={formatedData.length === 0}
                    onClick={handleApprove}
                    name="approve"
                    variant="contained"
                  >
                    Aprovar
                  </Button>
                  <Button onClick={handleReprove} name="reprove" variant="contained" color="error">
                    Reprovar
                  </Button>
                </Paper>
              </form>
            </Grid>
          </Grid>
        </>
      )}

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        open={fuelSuggestionSuccess}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={reset}
      >
        <Alert severity="success" variant="filled" onClose={reset}>
          <AlertTitle>Sucesso</AlertTitle>
          Sugestão de preço validada com sucesso!
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!fuelSuggestionError}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={reset}
      >
        <Alert severity="error" variant="filled" onClose={reset}>
          <AlertTitle>Erro</AlertTitle>
          Erro ao validar sugestão de preço: {fuelSuggestionError?.response?.data?.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default FuelSuggestion;
