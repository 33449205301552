import { useMutation } from '@tanstack/react-query';
import client from './client';
import { AxiosResponse } from 'axios';

export type NotifyAllUsersRequest = {
  notificationToken: string;
  title: string;
  body: string;
  data?: object;
};

export type NotifyAllUsersResponse = {};

const useNotifyAllUsers = () => {
  const { isLoading, error, status, reset, mutateAsync } = useMutation<
    AxiosResponse<NotifyAllUsersResponse>,
    any,
    NotifyAllUsersRequest
  >(['notifyAllUsers'], (notification) =>
    client.post('notification/notifyAllUsers', notification).then((res) => res.data)
  );

  const success = status === 'success';

  return { isLoading, error, success, reset, notifyAllUsers: mutateAsync };
};

export default useNotifyAllUsers;
