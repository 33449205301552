import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

export const formatBrazilianDate = (date: Date) => {
  return dayjs(date).format('DD/MM/YYYY HH:mm:ss');
};

export const parseBrazilianDate = (stringDate: string) => {
  const date = dayjs.tz(stringDate, 'DD/MM/YYYY', 'America/Sao_Paulo').toDate();
  if (dayjs(date).isValid()) {
    return date;
  }

  return undefined;
};
