import { Box, Button, Container, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import useSearchGasStation, { ResponseItem } from '../../services/useSearchGasStation';
import GasStationTable from './CustomTable.tsx/GasStationTable';

const GasStation: React.FC = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState<string>();
  const [searchInput, setSearchInput] = useState<string>();
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof ResponseItem>('createdAt');
  const { isLoadingSearch, searchData, searchError } = useSearchGasStation(search, page, rowsPerPage, orderBy, order);

  const handleSearch = () => {
    setSearch(!searchInput || searchInput.length === 0 ? undefined : searchInput);
  };

  return (
    <Container maxWidth={false}>
      <Box>
        <Typography variant="h4">Postos de combustível</Typography>

        <Box marginY={3}>
          <TextField
            id="search"
            label="Pesquise por qualquer campo"
            variant="outlined"
            value={searchInput}
            size="small"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchInput(event.target.value)}
            sx={{
              width: 400,
              marginRight: 2,
            }}
          />

          <Button onClick={handleSearch} variant="contained">
            Aprovar
          </Button>
        </Box>

        <GasStationTable
          searchData={searchData}
          order={order}
          orderBy={orderBy}
          page={page}
          rowsPerPage={rowsPerPage}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Box>
    </Container>
  );
};

export default GasStation;
