import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import client from './client';

export interface Request {}

export type Response = {};

const useProcessGeolocation = () => {
  const { isLoading, error, data, mutate, reset } = useMutation<AxiosResponse<Response>, any, Request>(
    ['processGeolocation'],
    () => client.post<Response>(`/gas-station/process-geolocate`)
  );

  return {
    isLoadingProcessGeolocation: isLoading,
    processGeolocationError: error,
    processGeolocationSuccess: data?.status === 201,
    processGeolocationMutate: mutate,
    processGeolocationReset: reset,
  };
};

export default useProcessGeolocation;
