import React from 'react';
import { NumberFormatBase } from 'react-number-format';
import { NumberFormatBaseProps } from 'react-number-format/types/types';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function currencyFormatter(value: string) {
  const amount = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(((value || 0) as number) / 100);

  return `${amount}`;
}

const CurrencyInput = React.forwardRef<typeof NumberFormatBase<NumberFormatBaseProps>, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormatBase
        {...(other as any)}
        getInputRef={ref}
        format={currencyFormatter}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.formattedValue,
            },
          });
        }}
      />
    );
  }
);

export default CurrencyInput;
