import axios, { AxiosError } from 'axios';
import { redirect } from 'react-router-dom';
import { IAuthResponse } from '../contexts/AuthContext';
import { isExpired } from '../utils';

const client = axios.create({ baseURL: process.env.REACT_APP_API_URL });
let isRefreshing = false;

client.interceptors.response.use(
  (res) => res,
  async (error: AxiosError) => {
    const { config, response } = error;
    const originalRequest = config;

    if (response?.status === 401 && !isRefreshing) {
      isRefreshing = true;
      const authStorage = sessionStorage.getItem('@PrecoCombustivel:auth');

      if (!authStorage) {
        return Promise.reject();
      }

      let parsedAuth = JSON.parse(authStorage) as IAuthResponse;

      if (isExpired(parsedAuth.refreshTokenExpiresAt)) {
        redirect('/');
        return Promise.reject();
      }

      const refreshReponse = await client.get('/auth/refresh', {
        headers: { Authorization: `Bearer ${parsedAuth.refreshToken}` },
      });

      if (refreshReponse.status !== 200 || !refreshReponse.data) {
        redirect('/');
        return Promise.reject();
      }

      sessionStorage.setItem('@PrecoCombustivel:auth', JSON.stringify(refreshReponse.data));

      client.defaults.headers.common['Authorization'] = `Bearer ${refreshReponse.data.accessToken}`;
      if (originalRequest.headers) {
        originalRequest.headers['Authorization'] = `Bearer ${refreshReponse.data.accessToken}`;
      }

      isRefreshing = false;
      return client(originalRequest);
    }

    return Promise.reject(error);
  }
);

export default client;
