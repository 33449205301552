import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppLayout from '../components/AppLayout';
import FuelSuggestion from '../pages/FuelSuggestion';
import GasStation from '../pages/GasStation';
import Home from '../pages/Home';
import Notification from '../pages/Notification';
import UploadFuelFile from '../pages/UploadFuelFile';

const AuthenticatedRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AppLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/sugestao-de-preco" element={<FuelSuggestion />} />
          <Route path="/upload-preco-mensal" element={<UploadFuelFile />} />
          <Route path="/postos" element={<GasStation />} />
          <Route path="/notificacoes" element={<Notification />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AuthenticatedRoutes;
