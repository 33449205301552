import { useQuery } from '@tanstack/react-query';
import client from './client';

export type Response = {
  id: string;
  imageUrl: string;
  totalSuggestions: number;
  createdAt: Date;
  referenceDate: Date;
  distanceFromGasStation: number;
  createBy: string;
  gasStation: {
    id: string;
    name?: string;
    corporateName: string;
    CNPJ: string;
    flagUrl: string;
    flag: string;
    complement?: string;
    residentialNumber: string;
    address: string;
    district?: string;
    zipCode: string;
    city: string;
    state: string;
  };
};

const useGetFuelSuggestion = () => {
  const { isLoading, error, data, refetch } = useQuery<Response>(['getFuelSuggestion'], () =>
    client.get('fuel-suggestion').then((res) => res.data)
  );

  return { isLoading, error, fuelSuggestion: data, refetch };
};

export default useGetFuelSuggestion;
