export enum FuelType {
  GASOLINA,
  GASOLINA_ADITIVADA,
  ETANOL,
  DIESEL,
}

export const getFuelTypeLabel = (type: FuelType) => {
  switch (type) {
    case FuelType.GASOLINA:
      return 'Gasolina';
    case FuelType.GASOLINA_ADITIVADA:
      return 'Gasolina aditivada';
    case FuelType.ETANOL:
      return 'Etanol';
    case FuelType.DIESEL:
      return 'Diesel';
  }
};

export const getEnumValues = (enumObject: object) => Object.values(enumObject).filter((v) => typeof v === 'number');
