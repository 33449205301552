import { Outlet } from 'react-router-dom';
import SideNavbar from '../SideNavbar';

const AppLayout = () => {
  return (
    <div
      style={{
        padding: '20px 0px 0px 280px',
      }}
    >
      <SideNavbar />
      <Outlet />
    </div>
  );
};

export default AppLayout;
